import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBBtn,
} from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <MDBFooter bgColor='dark' className='text-center text-white text-lg-left' id="contacto">
        <MDBContainer className='p-4 pb-4'>
        <section className='mb-1'>
          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='facebook-f' />
          </MDBBtn>
          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='instagram' />
          </MDBBtn>
          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='linkedin-in' />
          </MDBBtn>
        </section>
        contacto <span className="text-info">barilocheaudiovisualhub@gmail.com</span>
      </MDBContainer>
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        <h6 style={{marginBottom: '0', fontWeight: '400'}}>&copy; {new Date().getFullYear()} <span className="text-info">FILM&CO</span> Todos los derechos reservados</h6>
         <span style={{fontSize: '13px'}}>diseño <a className='text-white-50 mx-1' href='http://www.kukadesign.com.ar/' target="blank"> kukadesign </a></span>
      </div>
    </MDBFooter>
  )
}
