import React,{useEffect, Fragment} from "react";
import { MDBContainer,
         MDBRow,
         MDBCol,
         MDBCard,
         MDBCardBody,
         MDBCardTitle,
         MDBCardText,
         MDBCardHeader,
         MDBCardFooter,
         MDBIcon,
         MDBBtn} from 'mdb-react-ui-kit';
         import AOS from 'aos';
         import 'aos/dist/aos.css';

export default function Servicios() {
    useEffect(()=> {
        AOS.init();
    },[]
    )
  return (

    <Fragment>
    <section className="py-5 bg-info bg-image servicios-back bg-dark" id="servicios">
        <MDBContainer>
        <MDBRow className="text-center">
                <h1 className='fw-light mt-0 p-0 lh-1 text-uppercase mb-1 text-white trabajos-titulo'>Servicios</h1>
                <h6 className='fw-normal mt-0 p-0 lh-1 mb-5 trabajos-subtitulo text-white pb-3'>Nuestros programas de trabajo.</h6>
        </MDBRow>

        </MDBContainer>
    </section>

    <section className="servicios-cards" >
        <MDBContainer>
        <MDBRow className="text-center row-cols-1 row-cols-md-3 g-3">
            <MDBCol data-aos="flip-right" data-aos-duration="2000">
                <MDBIcon fas icon="check-circle" size='3x'className="text-info mb-2 servicios-icons"/>
                <MDBCard>
                <MDBCardBody>
                    <MDBCardTitle className='text-info pt-3'>INCUBADORA DE PROYECTOS AUDIOVISUALES</MDBCardTitle>
                    <MDBCardText>
                    La Incubadora de Proyectos Audiovisuales (IPA) está orientada a acompañar a los realizadores audiovisuales en la gestación y el desarrollo de sus proyectos.  Dirigida a productores/as, directores/as, guionistas y quienes se desempeñan en el sector audiovisual y deseen llevar adelante la producción de un proyecto audiovisual.
                    </MDBCardText>
                    <a href='/servicios/incubadora' className="text-white fw-normal"><MDBBtn>ver más</MDBBtn></a>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>

            <MDBCol data-aos="flip-down" data-aos-duration="2000">
                <MDBIcon fas icon="check-circle" size='3x'className="text-info mb-2 servicios-icons"/>
                <MDBCard>
                <MDBCardBody>
                    <MDBCardTitle className='text-info pt-3'>PROGRAMA DE CAPACITACIÓN EN OFICIOS AUDIOVISUALES</MDBCardTitle>
                    <MDBCardText>
                    El “Programa de Capacitación en Oficios Audiovisuales” aportará bases firmes en la inclusión de muchos jóvenes, enfocándose principalmente en el desarrollo laboral de esta actividad económica no tradicional para nuestra región y con un gran crecimiento a nivel local y sobretodo mundial. 
                    </MDBCardText>
                    <a href='/servicios/capacitacion' className="text-white fw-normal"><MDBBtn>ver más</MDBBtn></a>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>

            <MDBCol data-aos="flip-left" data-aos-duration="2000">
                <MDBIcon fas icon="check-circle" size='3x'className="text-info mb-2 servicios-icons"/>
                <MDBCard>
                <MDBCardBody>
                    <MDBCardTitle className='text-info pt-3'>CONSULTORÍA Y DESARROLLOS</MDBCardTitle>
                    <MDBCardText>
                    Proveemos herramientas, respuestas y orientación a distintas necesidades y temas específicos: análisis de Guión, diseños de Biblias Transmedias y Desarrollos de Proyectos. Estos últimos permiten atravesar todas las etapas en el proceso de llevar una idea hasta convertirla en un producto audiovisual. Cada etapa tiene sus particularidades y el buen desarrollo de cada una trae la posibilidad de que el proyecto se vaya consolidando y creciendo.
                    </MDBCardText>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>

        </MDBContainer>
    </section>
    </Fragment>
  )
}
