import React, { useState } from 'react';
import logo from '../assets/images/logo-header.png'
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBContainer,
} from 'mdb-react-ui-kit';
import { HashLink } from 'react-router-hash-link';

export default function App() {
  const [showBasic, setShowBasic] = useState(false);
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if(window.scrollY > 0){
      setColor(true)
    }else{
      setColor(false)
    }
  }

  window.addEventListener ('scroll', changeColor)

  const handleClickScroll = (section) => {

    window.location.href = "/#" + section;

    //window.location.hash = '/#' + section;

    //const element = document.getElementById(section);
    //console.log(element.getBoundingClientRect().top)
    //if (element) {
      
      

      // var target = document.getElementById(section);
      // window.scroll(0, target);
    //}
  };

  

  return (
    <section className={color ? "menu-scroll" : "menu"}>

        <MDBNavbar className='navbar navbar-expand-lg navbar-dark shadow-0 '  fixed>
        <MDBContainer fluid>
          <MDBNavbarBrand href='/'>
            <img src={logo} alt="" />
          </MDBNavbarBrand>

          <MDBNavbarToggler
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowBasic(!showBasic)}
          >
          <MDBIcon icon='bars' fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current='page' onClick={() => handleClickScroll("nosotros")}>
                  Nosotros
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current='page' onClick={() => handleClickScroll("trabajos")}>
                  trabajos
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current='page' onClick={() => handleClickScroll("servicios")}>
                  Servicios
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink active aria-current='page' onClick={() => handleClickScroll("contacto")}>
                  Contactos
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
              </MDBNavbarItem>
            </MDBNavbarNav>
            <img
              src="https://flagcdn.com/w40/es.png"
              srcset="https://flagcdn.com/w80/es.png 2x"
              width="30"
              alt="Spain" />

          </MDBCollapse>
          </MDBContainer>
      </MDBNavbar>
    </section>
    
  );
}