import React from 'react'
import videoBg from '../assets/videos/nahuelito-trailer.mp4'

export default function Intro() {
  return (
    <section className="intro">
    <video src={videoBg}
             className='intro-video-cover' autoPlay loop muted />
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white text-center'>
              <h4 className='fw-normal mb-0 p-0 text-uppercase text-info'>Bienvenidos a</h4>
              <h1 className='fw-normal mt-0 p-0 lh-1 text-uppercase'>Film&co</h1>
              <h3 className='fw-lighter mt-0 p-0 lh-1 text-uppercase'>hacemos cine. somos cine.</h3>
            </div>
          </div>
        </div>
      
    </section>
  )
}
