import React from 'react';
import Header from './Header';
import Intro from './Intro';
import Nosotros from './Nosotros';
import Trabajos from './Trabajos';
import Servicios from './Servicios';

export default function Home() {
  return (
    <div>
        {/* // HEADER // */}
        <Header />
        {/* // INTRO // */}
        <Intro />
        {/* // NOSOTROS // */}
        <Nosotros />
        {/* // TRABAJOS // */}
        <Trabajos />
        {/* // SERVICIOS // */}
        <Servicios />
    </div>
  )
}
