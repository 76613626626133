import React,{useEffect} from "react";
import { MDBRipple,
         MDBContainer,
         MDBRow,
         MDBCol,
         MDBBtn } from 'mdb-react-ui-kit';
import image1 from '../assets/images/superficie-home.jpg'
import image2 from '../assets/images/ballester-home.jpg'
import image3 from '../assets/images/nofear-home.jpg'
import image4 from '../assets/images/capital-home.jpg'
import image5 from '../assets/images/rota-home.jpg'
import image6 from '../assets/images/patagonia-home.jpg'

import AOS from 'aos';
import 'aos/dist/aos.css';


export default function Trabajos() {
  const [isActive, setIsActive] = React.useState(false);
  useEffect(()=> {
        AOS.init();
    },[]
    )
  return (
    
    <section className="py-5 bg-info bg-image trabajos-back" id="trabajos">
        
    <MDBContainer>
      <MDBRow className="text-center">
        <h1 className='fw-light mt-0 p-0 lh-1 text-uppercase mb-1 text-white trabajos-titulo'>Nuestros trabajos</h1>
        <h6 className='fw-normal mt-0 p-0 lh-1 mb-5 trabajos-subtitulo text-white'>Estas son algunas de nuestras producciones en documentales, ficción y música.</h6>
        <MDBCol size='md-4 px-0'>
        <MDBRipple rippleTag='div' className='trabajos-item hover-zoom' data-aos="flip-left" data-aos-duration="800">
            <img src={image1} className='w-100 '  alt=""/>
            <a href='trabajos/bajo-superficie'>
            <div className='hover-overlay'>
                    <div className='d-flex justify-content-center align-items-center h-100 trabajos-text'> 
                        <div className="">
                            <h4 className='text-white mb-0 text-uppercase fadeInUp'>BAJO SUPERFICIE</h4>
                            <h6 className="text-info">2022 / Documental</h6>
                            <MDBBtn outline color='light'>Más información</MDBBtn>
                        </div>
                    </div>
                </div>
            </a>
            </MDBRipple>
        </MDBCol>
        <MDBCol size='md-4 px-0'>
            <MDBRipple rippleTag='div' className='trabajos-item hover-zoom' data-aos="flip-down" data-aos-duration="800">
            <img src={image2} className='w-100' alt=""/>
            <a href='trabajos/ballester-molina'>
                {/* <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.2)' }}></div> */}
                <div className='hover-overlay'>
                        <div className='d-flex justify-content-center align-items-center h-100 trabajos-text'>
                            <div>
                                <h4 className='text-white mb-0 text-uppercase'>LA BALLESTER MOLINA</h4>
                                <h6 className="text-info">2022 / Ficción</h6>
                                <MDBBtn outline color='light'>Más información</MDBBtn>
                            </div>
                        </div>
                </div>
            </a>
            </MDBRipple>
        </MDBCol>
        <MDBCol size='md-4 px-0'>
            <MDBRipple rippleTag='div' className='trabajos-item hover-zoom' data-aos="flip-right" data-aos-duration="800">
            <img src={image3} className='w-100' alt=""/>
            <a href='trabajos/no-fear'>
                {/* <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.2)' }}></div> */}
                <div className='hover-overlay'>
                        <div className='d-flex justify-content-center align-items-center h-100 trabajos-text'>
                            <div>
                                <h4 className='text-white mb-0 text-uppercase'>NO FEAR?</h4>
                                <h6 className="text-info">2019 / Instalación artística</h6>
                                <MDBBtn outline color='light'>Más información</MDBBtn>
                            </div>
                        </div>
                </div>
            </a>
            </MDBRipple>
        </MDBCol>
        <MDBCol size='md-4 px-0'>
            <MDBRipple rippleTag='div' className='trabajos-item hover-zoom' data-aos="flip-right" data-aos-duration="800" data-aos-delay="200">
            <img src={image4} className='w-100' alt=""/>
            <a href='trabajos/videoclips'>
                {/* <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.2)' }}></div> */}
                <div className='hover-overlay'>
                        <div className='d-flex justify-content-center align-items-center h-100 trabajos-text'>
                            <div>
                                <h4 className='text-white mb-0 text-uppercase'>Videoclips</h4>
                                <MDBBtn outline color='light'>Más información</MDBBtn>
                            </div>
                        </div>
                </div>
            </a>
            </MDBRipple>
        </MDBCol>
        <MDBCol size='md-4 px-0'>
            <MDBRipple rippleTag='div' className='trabajos-item hover-zoom' data-aos="flip-up" data-aos-duration="800" data-aos-delay="200">
            <img src={image5} className='w-100' alt=""/>
            <a href='produccion/la-manana-rota'>
                {/* <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.2)' }}></div> */}
                <div className='hover-overlay'>
                        <div className='d-flex justify-content-center align-items-center h-100 trabajos-text'>
                            <div>
                                <h4 className='text-white mb-0 text-uppercase'>La mañana rota</h4>
                                <h6 className="text-info">En desarrollo</h6>
                                <MDBBtn outline color='light'>Más información</MDBBtn>
                            </div>
                        </div>
                </div>
            </a>
            </MDBRipple>
        </MDBCol>
        <MDBCol size='md-4 px-0'>
            <MDBRipple rippleTag='div' className='trabajos-item hover-zoom' data-aos="flip-left" data-aos-duration="800" data-aos-delay="200">
            <img src={image6} className='w-100' alt=""/>
            <a href='produccion/patagonia-confidencial'>
                {/* <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.2)' }}></div> */}
                <div className='hover-overlay'>
                        <div className='d-flex justify-content-center align-items-center h-100 trabajos-text'>
                            <div>
                                <h4 className='text-white mb-0 text-uppercase'>Patagonia confidencial</h4>
                                <h6 className="text-info">En desarrollo</h6>
                                <MDBBtn outline color='light'>Más información</MDBBtn>
                            </div>
                        </div>
                </div>
            </a>
            </MDBRipple>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </section>
  )
}
