import { useEffect, useState } from 'react'
import header from '../assets/images/superficie-header.jpg'
import parse from 'html-react-parser';
import { useParams, useLocation } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
//import listReactFiles from 'list-react-files'
import { MDBCollapse, MDBBtn } from 'mdb-react-ui-kit'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { items } from "./Datos";

export default function Pages() {

  const [showShow, setShowShow] = useState(false);

  const toggleShow = () => setShowShow(!showShow);

    let { postSlug } = useParams();

    const filterd_items = items.filter( item => item.filtro === postSlug );

    const titulo = filterd_items.map((datos) => datos.titulo )
    const data = filterd_items.map((datos) => parse(datos.data) )

    console.log(titulo)

    // const listItems = filterd_items.map((datos) =>
    //     datos.info?.map((val) => {
    //         // if(val.titulo === "Trailer" || val.titulo === "Video") trailer = parse(val.data)
    //         if(val === "titulo") titulo = parse(val.data)
    //         return (
    //             <div style={{borderBottom: '1px solid #ddd', padding: '7px 0'}}><strong><span className="text-info">{val.titulo}</span> {parse(val.data)} </strong></div>
    //         )
    //     })
    // );

    function requireAll(r) { 
        var images = r.keys().map(r);
        return images;
     }
    
    switch (postSlug) {
      case 'la-manana-rota':
        var images = requireAll(require.context("../assets/images/la-manana-rota", false, /\.jpg$/));
        break
      case 'patagonia-confidencial':
        var images = requireAll(require.context("../assets/images/patagonia-confidencial", false, /\.jpg$/));
        break
      default:

      break
        
    }
    
    let gallery=[];
    if(images){
      images.forEach((image) => {
        const img = new Image();
        img.src = image;
        gallery.push({ src: image , width: img.width , height: img.height});
      });
    }
    
    const [index, setIndex] = useState(-1);

    const handleClick = (index: number, item: gallery) => setIndex(index);

  return (
    
    <section className="pages ">
      <div style={{backgroundColor: '#000', paddingTop: '100px'}} />
      <div style={{backgroundImage: `url(${header})`}}>
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
            <div className='d-flex justify-content-center align-items-center' style={{minHeight: '250px'}}>
                <h1 className='fw-lighter mt-0 p-0 lh-1 text-uppercase text-white'>{titulo}</h1>
            </div>
        </div>
      </div>
        <div className="container">
            <div className="row">
                <div className="col-md-6 mb-0">
                    <div className="shadow-5-strong rounded p-4" style={{backgroundColor: '#fff', minHeight: '400px', top: '-40px', position: 'relative'}}> 

                            <Gallery 
                                images={gallery}
                                onClick={handleClick}
                                enableImageSelection={false}
                            />

                            <Lightbox
                                slides={gallery}
                                open={index >= 0}
                                index={index}
                                close={() => setIndex(-1)}
                            />

                        </div>

                </div>
            
            <div className="col-md-6 mb-5">
              <div className="shadow-5-strong rounded px-4 py-2 bg-info text-white" style={{backgroundColor: '#eee', top: '-10px', position: 'relative'}}>
                <h3 className='m-0 fw-light'>Información</h3>
              </div>
              <div className="shadow-5-strong rounded px-4 py-2" style={{backgroundColor: '#eee', minHeight: '400px',  position: 'relative'}}>
                {data}
              </div>
            </div>
        </div>
            
        </div>
    </section>
    
  )
}
