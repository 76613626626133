import React, { useEffect,useState, useRef } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';
import image1 from '../assets/images/nosotros-1.jpg'

import AOS from 'aos';
import 'aos/dist/aos.css';

export default function App() {

  const nosotros = useRef(null);
  useEffect(()=> {
      AOS.init();
  },[]
  )

  return (
    <section id="nosotros" style={{padding: '70px 0px'}}>
    <MDBContainer>
      <MDBRow>
        <MDBCol size='md-5' data-aos="fade-right" data-aos-duration="1000">
          <img src={image1} alt="" className='img-fluid shadow-4' />
        </MDBCol>
        <MDBCol size='md-7 align-self-center' data-aos="fade-left" data-aos-duration="1000">

        <h2 className="text-info">FILM&CO – HUB AUDIOVISUAL BARILOCHE</h2>
        
        
          <h5>“Punto nodal regional, desde donde se promueve el sector audiovisual, se producen y coproducen contenidos, y se trabaja en forma integral con el espacio privado y público en todos los niveles y estamentos”.</h5>
          <p className="mb-1">
            FILM&CO es un espacio estratégico 
            para el desarrollo del sector audiovisual regional.

            Lo caracteriza una dinámica de trabajo asociativo entre instituciones, empresas y emprendedores del sector audiovisual y tecnológico en pos de su desarrollo.

            Es un escenario de trabajo, capacitación y colaboración abierto a todos los sectores vinculados con la industria audiovisual y especialmente aquellos relacionados con tecnologías emergentes e innovación creativa.

            
          </p>
          <p className="mb-1">
            Tiene como creadores y líderes de su conducción a Roxana Cuezzo (Psicóloga y guionista) y a Miguel Ángel Rossi (Cineasta).
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </section>
  );
}