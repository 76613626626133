import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Scroll from './components/Scroll';
import Home from './components/Home';
import Pages from './components/Pages';
import Produccion from './components/Produccion';
import Servicios from './components/Servicios-page';
import NotFound from './components/NotFound';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import React from 'react';

function App() {

  document.title = "Film&co";

  return (
      
      <div className="App">


          {/* //SCROLL // */}
          <Scroll />
          {/* // HEADER // */}
          <Header />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/trabajos" element={<Home />} />
              <Route path="/trabajos/:postSlug" element={<Pages />} />
              <Route path="/produccion" element={<Home />} />
              <Route path="/produccion/:postSlug" element={<Produccion />} />
              <Route path="/servicios" element={<Home />} />
              <Route path="/servicios/:postSlug" element={<Servicios />} />
              <Route path="/#nosotros" element={<Home />} />
              <Route path="*" component={<NotFound />} />
            </Routes>
          </BrowserRouter>
          {/* // FOOTER // */}
          <Footer />
        </div>
    
  );
}

export default App;
