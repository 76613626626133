import React from 'react'
import ScrollToTop from "react-scroll-to-top";

export default function Scroll() {

  const scrollStyle = {backgroundColor:'#54b4d3', borderRadius: '20px'}
  return (
    <ScrollToTop smooth color="#fff" width="20" height="20" top="200" style={scrollStyle} />
  )
}
